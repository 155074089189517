* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100% !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  overscroll-behavior: none !important;
}

body {
  background-color: rgba(166, 181, 185, 0.17) !important;
  height: 100% !important;
  font-family: 'Helvetica Neue, Helvetica, sans-serif' !important;
}

a {
  text-decoration: none;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
p {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
span {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
#root {
  height: 100%;
}

.card-specific {
  max-width: 345;
  margin-top: 50px;
  padding-bottom: 10px;
}

.check-box {
  margin-top: 20px !important;
}

.error {
  color: red;
}

.cancel-link {
  color: rgb(121, 121, 121) !important;
  text-decoration: none;
}

[id*='feedback_minimized'] {
  bottom: 250px !important;
}

[class*='feedback_minimized_label'] {
  width: 0 !important;
  padding: 4px 21px 1px 5px !important;
}

[class*='feedback_minimized_label_text'] {
  width: 0 !important;
  padding: 4px 16px 1px 5px !important;
}

[class*='icon_emotion_default'] {
  margin: 0px 0 0 -3px !important;
}
